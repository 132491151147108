<template>
  <v-container>
    <BaseList
      flat
      color="primary"
      :title="$t('transfer_index_title')"
      :section="$route.meta.section"
      :headers="headers"
      :items="instructions"
      :loading="loading"
      :totalRows="totalRows"
      :breadcrumbs="false"
      :elevation="0"
      :height="'100%'"
      :buttons="{ search: true, add: (!isExecutive && !handleAuthorityAccounts()) }"
      @add="handleNew"
      @edit="handleEdit"
      @refresh="getData"
      @delete="handleDelete"
      @options="handleOptions"
      @search="handleSearch"
      :search="search"
    >
      <template #header.alias>
        <span> {{ $t('transfer_index_alias') }} </span>
      </template>
      <template #header.trType>
        <span> {{ $t('transfer_index_type') }} </span>
      </template>
      <template #header.bnfBankName>
        <span> {{ $t('transfer_index_bank') }} </span>
      </template>
      <template #header.status>
        <span> {{ $t('transfer_index_status') }} </span>
      </template>
      <template #header.bnfBankAccNumber>
        <span> {{ $t('transfer_index_account') }} </span>
      </template>
      <template #header.operation>
        <span> {{ $t('transfer_index_action') }} </span>
      </template>
      <template #item.operation="{ item }">
        <span class="d-flex justify-center" v-if="item.isCertified">
          <v-btn
            color="red"
            rounded
            outlined
            class="py-4 px-5 mr-3 retire-btn"
            text
            small
            v-can="'create-funds-withdrawal-request'"
            v-if="!handleAuthorityAccounts()"
            @click="handleRetire(item)"
          >
            {{ $t('btn_withdraw') }}
          </v-btn>
          <v-btn
            color="success"
            rounded
            outlined
            class="py-4 deposit-btn"
            text
            small
            v-can="'create-notification-funds-deposits'"
            v-if="!handleAuthorityAccounts()"
            @click="handleDeposit(item)"
          >
            {{ $t('btn_deposit') }}
          </v-btn>
        </span>
        <span v-else>
          <v-btn
            color="info"
            rounded
            outlined
            class="py-4 certified-btn"
            text
            small
            v-can="'certify-permanent-instructions'"
            v-if="!handleAuthorityAccounts()"
            @click="handleValidationModal(item)"
          >
            {{ $t('btn_pending_certify') }}
          </v-btn>
        </span>
      </template>
      <template #item.bnfBankName="{ item }">
        <span>{{
          item.accountType == 'nfiAccount' ? item.nfiName : item.bnfBankName
        }}</span>
      </template>
      <template #item.status="{ item }">
        <span>{{ item.isCertified ? 'Certificada' : 'Debe Certificar'}}</span>
      </template>
      <template #item.bnfBankAccNumber="{ item }">
        <span>{{
          item.accountType == 'nfiAccount'
            ? handleBankAccount(item.nfiAccountNumber, item.isCertified)
            : handleBankAccount(item.bnfBankAccNumber, item.isCertified)
        }}</span>
      </template>
    </BaseList>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BaseList from '@/components/commons/BaseList'
import ClientTransferInstructionsService from '@/services/clientTransferInstructions'
import CertifyModal from './partials/CertifyModal.vue'
import TwoFactorModal from '@/components/commons/TwoFactorModal.vue'

export default {
  components: { BaseList },
  data() {
    return {
      options: {},
      dialog: false,
      loading: false,
      totalRows: 0,
      instructions: [],
      certification: {},
      search: ''
    }
  },
  async created() {
    await this.getData()
    if (!this.instructions.length) {
      if (this.handleAuthorityAccounts()) {
        const name = this.isExecutive ? 'Retire' : 'Home'
        this.$router.push({ name })
      } else {
        this.$router.push({ name: 'PermanentInstructionsCreate' })
      }
    }
  },
  computed: {
    ...mapState('account', ['account', 'authorityAccounts']),
    ...mapState('setting', ['settings']),
    ...mapState('auth', ['user']),

    headers() {
      if (!this.handleAuthorityAccounts()) {
        return [
          { text: 'Alias', value: 'alias' },
          { text: 'Tipo de Instrucción', value: 'trType', width: '3rem' },
          {
            text: 'Institución Financiera',
            value: 'bnfBankName',
            width: '11rem'
          },
          { text: 'Status', value: 'status' },
          {
            text: 'Número de Cuenta',
            value: 'bnfBankAccNumber',
            align: 'center'
          },
          { text: 'Operar', value: 'operation', align: 'center' }
        ]
      } else {
        return [
          { text: 'Alias', value: 'alias' },
          { text: 'Tipo de Instrucción', value: 'trType', width: '3rem' },
          {
            text: 'Institución Financiera',
            value: 'bnfBankName',
            width: '11rem'
          },
          { text: 'Status', value: 'status' },
          {
            text: 'Número de Cuenta',
            value: 'bnfBankAccNumber',
            align: 'center'
          }
        ]
      }
    },

    isExecutive() {
      const index = this.user?.avobjects?.findIndex(
        item => item.typeCode === 'authorityCode' && item.code == '1' // Code = 1 (Executive)
      )
      return index > -1
    }
  },
  methods: {
    ...mapActions('transfer', ['setPermanentInstruction']),
    async getData() {
      this.loading = true
      try {
        let data = await ClientTransferInstructionsService.find(
          this.account.code
        )
        this.instructions = data
        this.totalRows = data.length
      } catch (error) {
        if (error.response.status !== 401)
          this.$dialog.message.error('No se pudo cargar la información.')
      } finally {
        this.loading = false
      }
    },
    handleOptions(options) {
      this.options = options
      this.getData()
    },
    handleNew() {
      this.$router.push({ name: 'PermanentInstructionsCreate' })
    },
    handleEdit(item) {
      this.$router.push({ name: 'RegisterAccounts', params: { id: item.id } })
    },
    handleRetire(item) {
      item.fromIndex = true
      this.setPermanentInstruction(item)
      this.$router.push({ name: 'RetireCreate' })
    },
    handleDeposit(item) {
      item.fromIndex = true
      this.setPermanentInstruction(item)
      this.$router.push({ name: 'DepositCreate' })
    },
    // eslint-disable-next-line no-unused-vars
    async handleDelete(item) {
      const res = await this.$dialog.warning({
        text: '¿ Desea eliminar el registro ?',
        showClose: false
      })

      if (!res) return false

      try {
        /*
        await DisplayPeriodService.delete(item.id)
        */
        this.$dialog.notify.success('Registro eliminado con éxito.')
        await this.getData()
      } catch (e) {
        this.$dialog.message.error(e.title)
      }
    },
    handleBankAccount(acc, isCertified) {
      let account = isCertified ? acc : `...${acc.substring(acc.length - 4)}`
      return account
    },
    async handleCertifyModal(item) {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: '40%',
        transition: 'dialog-top-transition',
        transferInstructionId: item.transferInstructionId,
        bnfBankAccountNumber:
          item.accountType == 'nfiAccount'
            ? item.nfiAccountNumber
            : item.bnfBankAccNumber,
        showClose: false
      }

      const certify = await this.$dialog.showAndWait(CertifyModal, params)
      if (certify) this.getData()
    },
    handleAuthorityAccounts() {
      return (
        this.authorityAccounts.findIndex(
          item => item.code == this.account.code
        ) > -1
      )
    },
    handleSearch(search) {
      this.search = search
    },
    async handleValidationModal(item) {
      let twoFactorDeposit = this.settings.find(
        item => item.key === '2fa_deposit_notifications'
      )
      let twoFactorTransfer = this.settings.find(
        item => item.key === '2fa_transfer_requests'
      )
      let certifyModal = true
      if (twoFactorDeposit.value || twoFactorTransfer.value) {
        certifyModal = await this.handleTwoFactorModal(
          this.user.twofactor_habilitated
        )
      }
      certifyModal
        ? await this.handleCertifyModal(item)
        : this.$dialog.message.error(this.$t('twofactor_required'))
    },
    async handleTwoFactorModal(twofactor) {
      const params = {
        fullscreen: this.$vuetify.breakpoint.smAndDown,
        width: twofactor ? '40%' : '80%',
        transition: 'dialog-top-transition',
        persistent: false,
        hideOverlay: false,
        twoFactor: twofactor,
        hideSubmit: true,
        showClose: twofactor
      }
      return await this.$dialog.showAndWait(TwoFactorModal, params)
    }
  }
}
</script>

<style scoped>
.retire-btn:hover {
  background-color: red;
  color: white !important;
}

.deposit-btn:hover {
  background-color: #8bc34a;
  color: white !important;
}
.certified-btn:hover {
  background-color: #2196f3;
  color: white !important;
}

.v-link-dialog {
  text-align: left;
  font: normal normal bold 14px/17px Lato;
  letter-spacing: 0px;
  color: #00559e;
  opacity: 1;
}
.login-button {
  min-width: 130px !important;
  background: transparent linear-gradient(112deg, #00559e 0%, #0090df 100%) 0%
    0% no-repeat padding-box;
  color: white !important;
  box-shadow: 6px 10px 14px #00000029;
  border-radius: 28px;
  opacity: 1;
}

.v-title-card {
  text-align: left;
  font: normal normal bold 16px/38px Lato;
  letter-spacing: 0px;
  color: #212529;
  opacity: 1;
}
</style>
